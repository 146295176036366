import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'

export default function CTVCurbsidePickup () {
  const shareTitle = 'Curbside Pickup features Trexity on CTV Ottawa evening news'
  const articleLink = 'https://ottawa.ctvnews.ca/video?clipId=2200724'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Curbside Pickup features Trexity on CTV Ottawa evening news</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='news'>News</span>
                  <span>May 12, 2021</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <img src="/img/press/ctv-evening-news-rob.jpg" alt="Rob Woodbridge on CTV news" />
                <div className="snippet">The following preview is from <a href={articleLink} target="_blank" rel="noreferrer">CTV Ottawa</a></div>
                <p>OTTAWA — Curbside Pickup features Trexity</p>
                <p>A business that literally gets goods to the curb. Trexity is a delivery company, and partner to many local shops. Rob Woodbridge, COO, interviews live on CTV Ottawa evening news for Curbside Pickup segment.</p>
                <a href={articleLink} target="_blank" rel="noreferrer">Watch interview on CTV Ottawa</a>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/ottawa-restaurateurs-pivot" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Some Ottawa restaurateurs have pivoted to more pandemic-resistant businesses</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
